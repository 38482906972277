import {Fragment} from 'react'
import styled from 'styled-components'
import {Link} from 'react-router-dom'

import {translate} from 'react-i18next'
import Button from '@material-ui/core/Button'
import DateFormat from '../../components/Format/DateFormat'

const Id = styled.span`
  font-size: 12px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.6);
  line-height: 1.33;
  letter-spacing: 2px;
  display: none;

  @media (max-width: 525px) {
    display: block;
  }
`

const Title = styled(Link)`
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 8px;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
  display: none;

  @media (max-width: 525px) {
    display: block;
  }
`

const ButtonWrapper = styled(Link)`
  margin-top: 8px;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  @media (max-width: 525px) {
    justify-content: space-evenly;
  }
`

const Wrapper = styled.div`
  @media (max-width: 525px) {
    display: none;
  }
`

const Content = styled.div`
  display: grid;
  width: 100%;
  font-size: 14px;
  grid-template-columns: 40% 60%;
`

const ContentTitle = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.width ? `${props.width * 100}%` : '100%')};
  font-size: 14px;
`

const Span = styled.span`
  color: ${(props) => props.color || '#bcbcbc'};
  margin-bottom: 4px;
  word-break: break-word;
`

const InternalId = (props) => {
  if (!props.data.internalId && !props.data.metaInfo.internalId) {
    return null
  }

  const label = props.t('dealerList.placeholder.internalId')
  const internalId = props.data.metaInfo.internalId || props.data.internalId

  return <Id>{`${label}: ${internalId}`}</Id>
}

const HeaderTitle = (props) => {
  if (
    props.isScrollSectionVisible &&
    (!props.data.firstname || !props.data.lastname || !props.userId)
  ) {
    return null
  }

  return (
    <ContentTitle>
      <Title to={`/dealers/edit/${props.userId}`}>
        {props.data.firstname} {props.data.lastname}
      </Title>
    </ContentTitle>
  )
}
const dealerEditPage = ({userId, search = ''}) =>
  `/dealers/edit/${userId}${search}`

const getActionButtonText = (props) => {
  return props.dealerEnabled
    ? props.t('button.edit')
    : props.t('general.action.view')
}

const RightHeader = (props) => (
  <Container>
    <InternalId {...props} />
    <HeaderTitle {...props} />
    <Wrapper isScrollSectionVisible={props.isScrollSectionVisible}>
      <Content>
        <Span>{props.t('dealer.page.field.firstName')}:</Span>
        <Span color='#7D7D7D' data-test='field-contact-first-name'>
          {props.data.firstname}
        </Span>
      </Content>
      <Content>
        <Span>{props.t('dealer.page.field.lastName')}:</Span>
        <Span color='#7D7D7D' data-test='field-contact-last-name'>
          {props.data.lastname}
        </Span>
      </Content>
      <Content>
        <Span>{props.t('dealer.page.field.email')}:</Span>
        <Span color='#7D7D7D' data-test='field-contact-email'>
          {props.data.email}
        </Span>
      </Content>
      {!props.isScrollSectionVisible ? (
        <>
          <Content>
            <Span>{props.t('global.createdAt.label')}:</Span>
            <Span color='#7D7D7D' data-test='field-created-at'>
              <DateFormat data={props.data.createdAt} time />
            </Span>
          </Content>
          <Content>
            <Span>{props.t('global.updatedAt.label')}:</Span>
            <Span color='#7D7D7D' data-test='field-updated-at'>
              <DateFormat data={props.data.updatedAt} time />
            </Span>
          </Content>
        </>
      ) : null}
    </Wrapper>
    {!props.isScrollSectionVisible || !props.userId ? (
      <ButtonWrapper
        to={dealerEditPage({userId: props.userId, search: props.search})}
      >
        <Button variant='raised' color='primary' data-test='dealer-edit'>
          {getActionButtonText(props)}
        </Button>
      </ButtonWrapper>
    ) : null}
  </Container>
)

export default translate()(RightHeader)
