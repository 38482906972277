import * as fields from './fields'
import {ApiClient} from '@fcg/admin-api-client'
import {
  getCountryOptionsConfig,
  formatPlaceType,
  formatPlaceResponse,
  formatPlaceListTypeResponse,
  getSingleResult,
  encodeMetaInfo,
  formatCreatePlaceResponse,
  formatUpdatePlaceResponse,
  formatSort
} from './helpers'
import {
  transformEmptyValues,
  transformToString,
  removeEmptyValues,
  formatCountry
} from '../common/helpers'

const client = new ApiClient('car')

export function getPlacesList(params = {}) {
  return (
    client
      .withTransform(formatPlaceType)
      // TODO: cache:false as a workaround for agressive caching on service side
      .query('place', {...params, cache: false}, fields.placeList)
  )
}

export const getPlacesListRegion = (country) => {
  const params = {
    country: country,
    sort: [
      {order: 'DESC', field: 'active'},
      {order: 'ASC', field: 'location'}
    ],
    cache: false
  }

  return client
    .withTransform([formatCountry, formatSort])
    .query('place', {...params, cache: false}, fields.locationList)
    .then((res) => res.place.list)
}

export function getLocationList(params = {}) {
  return (
    client
      .withTransform([formatCountry, formatPlaceType, formatSort])
      // TODO: cache:false as a workaround for agressive caching on service side
      .query('place', {...params, cache: false}, fields.locationList)
      .then(formatPlaceListTypeResponse)
  )
}

export function getLocation(params = {}) {
  return (
    client
      // TODO: cache:false as a workaround for agressive caching on service side
      .query('place', {...params, cache: false}, fields.locationList)
      .then(formatPlaceResponse)
      .then(getSingleResult)
  )
}

export function createLocation(params = {}) {
  return client
    .withTransform([transformEmptyValues, formatCountry, encodeMetaInfo])
    .mutation('createPlace', params, fields.locationUpdate)
    .then(formatCreatePlaceResponse)
}

export function updateLocation(params = {}) {
  return client
    .withTransform([transformEmptyValues, encodeMetaInfo])
    .mutation('updatePlace', params, fields.locationUpdate)
    .then(formatUpdatePlaceResponse)
}

export function getCountryOptions(types = null, params = {}, options = null) {
  const request = options || getCountryOptionsConfig(types)

  return client
    .withTransform(formatCountry)
    .aliasedQuery('countryOptions', request, params, fields.countryOptionList)
}

export const createCountryOptionReqWithFields = (customFields) =>
  function getCountryOptionsForTypeWithFields(types = null, params = {}) {
    return client
      .withTransform([removeEmptyValues, formatCountry])
      .aliasedQuery('countryOptions', types, params, customFields)
  }

export const getCountryOptionsWithBasicProps = createCountryOptionReqWithFields(
  fields.basicCountryOptionsList
)

export function getCountryOptionsForType(types = null, params = {}) {
  return client
    .withTransform(formatCountry)
    .aliasedQuery('countryOptions', types, params, fields.countryOptionList)
}

export function deletePlace(params = {}) {
  return client.mutation('deletePlace', params)
}

export function deleteCar(params = {}) {
  return client.mutation('deleteCar', params)
}

export function deactivatePlace(params = {}) {
  return client
    .withTransform(transformEmptyValues)
    .mutation('updatePlace', params)
}

export function createCarViaAppointment(params = {}) {
  return client
    .withTransform([transformEmptyValues, formatCountry])
    .mutation('createCarViaAppointment', params, fields.carDataField)
}

export function createCar(params = {}) {
  return client
    .withTransform([
      (data) => transformToString(data, fields.carDataStringFields)
    ])
    .mutation('createCar', params, fields.carCreateField)
}

export const getCarPayments = (params) =>
  client.query('car', params, fields.carPaymentDetailsList)

export const getCarDetails = (params) =>
  client.query('car', params, fields.carDetailsList)

export function getTransitsJobs(params = {}) {
  return client.query('transitJob', params, fields.transitJobList)
}

export function createTransitJob(params = {}) {
  return client
    .withTransform(formatCountry)
    .mutation('createTransitJob', params, fields.transitJobFields)
}

export function cancelTransitsJob(params = {}) {
  return client.mutation('cancelTransitJob', params, fields.transitJobFields)
}

export function updateTransitsJob(params = {}) {
  return client.mutation('updateTransitJob', params, fields.transitJobFields)
}

export function finishTransitJob(params) {
  return client.mutation('finishTransitJob', params, fields.transitJobFields)
}

export function startTransitJob(params) {
  return client.mutation('startTransitJob', params, fields.transitJobFields)
}

export function confirmTransitJob(params) {
  return client.mutation('confirmTransitJob', params, ['id'])
}

export function rejectTransitJob(params) {
  return client.mutation('rejectTransitJob', params, ['id'])
}
