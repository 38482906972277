export const listMetaFields = [
  'legalCompanyName',
  'status',
  'companyName',
  'email',
  'internalId',
  'dealerManager',
  'phone',
  'street',
  'city',
  'maxAmountBids',
  'businessCategory',
  'places',
  'taxId'
]

export const permissionFields = [
  'id',
  'entity',
  'type',
  'label',
  'applicationName'
]

export const user = {
  all: ['id', 'firstname', 'lastname', 'email', 'groups', 'internalId'],
  userInfo: [
    'id',
    'firstname',
    'lastname',
    'email',
    'groups',
    'internalId',
    'isCheckedIn',
    ['roles', ['id', 'name', 'country']],
    ['permissions', permissionFields],
    ['metaInfo', listMetaFields]
  ],
  list: [
    [
      'list',
      [
        'id',
        'firstname',
        'lastname',
        'email',
        'groups',
        'dealerManager',
        'internalId',
        'createdAt',
        'isCheckedIn',
        ['metaInfo', [...listMetaFields]]
      ]
    ],
    'count'
  ],
  delete: ['deleted'],
  changePassword: ['changed']
}

const dealerMetaFields = [
  'companyName',
  'legalCompanyName',
  'bankDetails',
  'businessType',
  'firstName',
  'lastName',
  'email',
  'phone',
  'street',
  'zipCode',
  'city',
  'district',
  'personType',
  'paymentTerms',
  'registrationId',
  'taxId',
  'taxOffice',
  'carPreferences',
  'dealerCategory',
  'dealerManager',
  'dealerSize',
  'dealerLocations',
  'comments',
  'status',
  'maxAmountBids',
  'authorizedPickupPersons',
  'blockedReason',
  'onboardingStatus',
  'inactiveReason',
  'rejectedReason',
  'legalRepresentativeName',
  'legalRepresentativeLastName',
  'legalRepresentativeNationality',
  'legalRepresentativeProfesion',
  'legalRepresentativeId',
  'dealerTaxOfficeCommune',
  'dealerTaxOfficeCity',
  'dealersPublicDeedIncorporationDate',
  'dealersPublicDeedNotary',
  'dealersPublicDeedNotaryCity',
  'virtualAccountOrWalletId',
  'termAccepted',
  'businessCategory',
  'competitors',
  'guarantees',
  'keyContacts',
  'averageStock',
  'idealStock',
  'attachments',
  'legallyRegistered',
  'onboardingAgent',
  'internalId',
  'phone2',
  'nationality',
  'taxIdExpiryDate',
  'companyTradeLicense',
  'companyTaxId',
  'companyTransportAuthorityNo',
  'systemSetCreditLimit',
  'systemSetDepositAmount',
  'deposit',
  'registrationType',
  'invoicingType',
  'storageTime',
  'expectedDelivery',
  'expectedCarCare',
  'expectedFinancing',
  'legalRepresentativePhone',
  'legalRepresentativeEmail',
  'legalAdress',
  'leadSource',
  'employeeCount',
  'carPreferencesPrice',
  'monthlyTurnover',
  'estimatedMargin',
  'dealerAppsChannels',
  'bankAccountNumber',
  'bankAccountOwner',
  'onboardingDecisionDate',
  'onboardingRejectionReason',
  'dealerFarmer',
  'dealerHunter',
  'dealerBidManager',
  'depositBalance',
  'numberPurchasableCar',
  'isNewsLetterAccepted',
  'onboardingDate',
  'offboardingDate',
  'companyTypeId',
  'regon',
  'region',
  'postCode'
]

const individualDealerMetaFields = [
  ...dealerMetaFields,
  'cityId',
  'stateId',
  'state'
]

export const dealer = {
  all: [...user.all, ['metaInfo', [...dealerMetaFields]]],
  list: [...user.list],
  individual: [
    [
      'list',
      [...user.all, ['metaInfo', [...individualDealerMetaFields]]],
      'count'
    ]
  ],
  profile: [
    [
      'list',
      [
        'id',
        'firstname',
        'lastname',
        'email',
        'createdAt',
        'updatedAt',
        'internalId',
        'dealerManager',
        [
          'metaInfo',
          [
            'status',
            'street',
            'city',
            'zipCode',
            'phone',
            'phone2',
            'internalId',
            'maxAmountBids',
            'isNewsLetterAccepted'
          ]
        ]
      ]
    ]
  ]
}

export const group = {
  all: ['list', ['id', 'name']],
  add: ['id'],
  delete: ['id']
}

const singleRoleFields = ['id', 'name']

export const singleRoles = {
  list: [['list', singleRoleFields]]
}

export const role = {
  list: [['list', singleRoleFields]],
  users: [['list', [['users', ['id, firstname, lastname, email']]]]],
  permissions: [['list', [['permissions', permissionFields]]]],
  singleRoleFields
}

export const permission = {
  list: [['list', permissionFields]]
}

export const driverFields = [
  [
    'list',
    ['id', 'firstname', 'lastname', 'internalId', ['driver', ['documentId']]]
  ]
]
